import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  TextField,
  Collapse,
  Avatar,
  Divider,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReportIcon from "@mui/icons-material/TableChart";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import MyProgram from "@mui/icons-material/LibraryBooks";
import LearIcon from "@mui/icons-material/LocalLibrary";
import JournalIcon from "@mui/icons-material/Book";
import LeaderBoardIcon from "@mui/icons-material/Groups";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import TeamIcon from "@mui/icons-material/Diversity2";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import NotificationNoneIcon from "@material-ui/icons/NotificationsNone";
import linear_logo from "../../assests/images/linear_logo.png";
import linear_text from "../../assests/images/linear_text.png";
import UserDashboard from "../User/UserDashboard";
import TeamLeaderboard from "../Team/Leaderboard";
import MyPrograms from "../Program/MyPrograms";
import Reports from "../Admin/Reports";
import Achievements from "../UserProfile/Achievment";
import SavedTips from "../UserJournal/Tips";
import FlashCardsJournalComponent from "../UserJournal/FlashCard";
import UserChallenge from "../UserJournal/Challenge";
import Notes from "../UserJournal/Notes";
import Settings from "../UserProfile/Settings";
import TeamAdmin from "../Team/Admin";
import SearchEngine from "../../components/SearchEngine/SearchEngine";
import GraphCharts from "../GraphCharts/GraphCharts";

const drawerWidth = 240;
const miniDrawerWidth = 65;
const headerHeight = 64;

const AnalyticDash = () => {
  const [showProfileOpen, setShowProfileOpen] = useState(true);
  const [showDashboard, setShowDashboard] = useState(true);
  const [selectedItem, setSelectedItem] = useState("Learn");
  const [journalComponent, setJournalComponent] = useState("tips");
  const [profileOpen, setProfileOpen] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const [open, setOpen] = useState(false);

  const [showSearchEngine, setShowSearchEngine] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const deleteUser = useStoreActions((a) => a.user.delete);

  const menuItems = [{ text: "Reports", icon: <ReportIcon /> }];

  const menuBottomItems = [
    {
      text: "Logout",
      icon: (
        <LogoutIcon
          onClick={(e) => {
            localStorage.clear();
            sessionStorage.clear();
            deleteUser();
            history.push("/user/login");
          }}
        />
      ),
    },
  ];

  const profileSubItems = [
    { text: "Achievements", icon: <TrophyIcon /> },
    { text: "Journal", icon: <JournalIcon /> },
    { text: "Team", icon: <TeamIcon /> },
    { text: "Settings", icon: <SettingsIcon /> },
  ];

  const dashboardSubItems = [
    { text: "Learn", icon: <LearIcon /> },
    { text: "LeaderBoard", icon: <LeaderBoardIcon /> },
    { text: "Analytics", icon: <BarChartIcon /> },
    { text: "My Program", icon: <MyProgram /> },
  ];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleProfileToggle = () => {
    setProfileOpen(!profileOpen);
  };

  const handleDashboardToggle = () => {
    setDashboardOpen(!dashboardOpen);
  };

  const displayJournalComponent = () => {
    if (journalComponent === "tips") return <SavedTips />;
    else if (journalComponent === "todo") return <FlashCardsJournalComponent />;
    else if (journalComponent === "challenge") return <UserChallenge />;
    else if (journalComponent === "note") return <Notes />;
  };

  const displayComponent = () => {
    if (selectedItem === "Learn") {
      return <UserDashboard />;
    } else if (selectedItem === "LeaderBoard") {
      return <TeamLeaderboard />;
    } else if (selectedItem === "Analytics") {
      return <GraphCharts />;
    } else if (selectedItem === "My Program") {
      return <MyPrograms />;
    } else if (selectedItem === "Reports") {
      return <Reports />;
    } else if (selectedItem === "Achievements") {
      return <Achievements />;
    } else if (selectedItem === "Journal") {
      return (
        <div className="bg-white rounded-lg">
          <div className="flex flex-row gap-16 bg-white p-4 mx-4 my-4">
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("tips")}
              style={
                journalComponent === "tips"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Tips
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("todo")}
              style={
                journalComponent === "todo"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Flashcards
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("challenge")}
              style={
                journalComponent === "challenge"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Challenge
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("note")}
              style={
                journalComponent === "note"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              My notes
            </div>
          </div>
          {displayJournalComponent()}
        </div>
      );
    } else if (selectedItem === "Team") {
      return <TeamAdmin />;
    } else if (selectedItem === "Settings") {
      return <Settings />;
    }
  };

  const drawer = (
    <div>
      <List
        sx={{
          paddingTop: "0px",
        }}
      >
        {/* Collapsible Dashboard Items*/}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleDashboardToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              className={open ? "" : "opacity-0"}
            />
            {showDashboard && (dashboardOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showDashboard && (
          <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {dashboardSubItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
        {/* Main Menu Top Items */}
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding className="block">
            <ListItemButton
              selected={selectedItem === item.text}
              onClick={() => setSelectedItem(item.text)}
              className={`min-h-[48px] justify-center ${
                open ? "px-2.5" : "px-2.5 py-3"
              }`}
            >
              <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                className={open ? "" : "opacity-0"}
              />
            </ListItemButton>
          </ListItem>
        ))}
        {/* Collapsible Profile Items*/}
        <ListItem disablePadding className="block">
          <ListItemButton
            onClick={handleProfileToggle}
            className={`min-h-[48px] justify-center ${
              open ? "px-2.5" : "px-2.5 py-3"
            }`}
          >
            <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              className={open ? "" : "opacity-0"}
            />
            {open && (profileOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </ListItem>
        {showProfileOpen && (
          <Collapse in={profileOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {profileSubItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={selectedItem === item.text}
                  onClick={() => setSelectedItem(item.text)}
                  className="pl-14 min-h-[40px]"
                >
                  <ListItemIcon className="min-w-0 mr-3">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}

        {/* Main Menu Bottom Items */}
        <Divider className="my-2" />
        {menuBottomItems.map((item) => (
          <ListItem key={item.text} disablePadding className="block">
            <ListItemButton
              selected={selectedItem === item.text}
              onClick={() => setSelectedItem(item.text)}
              className={`min-h-[48px] justify-center ${
                open ? "px-2.5" : "px-2.5 py-3"
              }`}
            >
              <ListItemIcon className={`min-w-0 ${open ? "mr-3" : "mr-auto"}`}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                className={open ? "" : "opacity-0"}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box className="flex flex-col h-screen">
      <CssBaseline />
      {/* Header */}
      <AppBar
        position="fixed"
        className="bg-white text-black z-50"
        sx={{
          backgroundColor: "#003366",
        }}
      >
        <Toolbar className="flex justify-between items-center">
          {/* Left section: Hamburger and Logo */}
          <div className="flex items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className="mr-2"
            >
              <MenuIcon />
            </IconButton>
            {/* Logo */}
            <Box className="flex items-center ml-2">
              <img alt="Linear Logo" src={linear_logo} className="h-12 mr-2" />
              <img alt="Linear Text" src={linear_text} className="h-16" />
            </Box>
          </div>

          {/* Right: Search and Avatar */}
          <div className="flex justify-between items-center">
            {/* Search Field */}
            <TextField
              sx={{
                // flexGrow: 1,
                maxWidth: "110px",
                margin: "0 16px",
                maxHeight: "40px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--white)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--white) !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--white)",
                  },
                },
                input: {
                  color: "var(--white)",
                },
              }}
              onClick={() => {
                setShowSearchEngine(true);
              }}
              placeholder="Search...."
              variant="outlined"
              size="small"
              className="hidden md:block cursor-pointer"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    sx={{
                      color: `var(--white)`,
                    }}
                  />
                ),
                readOnly: true,
              }}
            />
            {/* Notification */}
            <NotificationNoneIcon className="h-4 mr-2" />
            {/* Avatar */}
            <Avatar className="bg-blue-500">U</Avatar>
          </div>
        </Toolbar>
      </AppBar>
      {/* Section */}
      <Box className="flex flex-grow pt-16" sx={{ backgroundColor: "#F6F6F6" }}>
        {/* Drawer Content Goes Here */}
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : miniDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : miniDrawerWidth,
              boxSizing: "border-box",
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              overflowX: "hidden",
              top: `${headerHeight}px`,
              height: `calc(100% - ${headerHeight}px)`,
              backgroundColor: "var(--background)",
            },
          }}
          open={open}
        >
          {drawer}
        </Drawer>
        {/* Content Goes Here */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginTop: 0,
            width: {
              sm: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
            },
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            backgroundColor: "#F6F6F6",
          }}
          // className="mt-0"
        >
          <Box sx={{ marginTop: "2px" }}>{displayComponent()}</Box>

          {/* <Typography paragraph>{selectedItem} content goes here.</Typography> */}
        </Box>
      </Box>
      <div>
        <SearchEngine open={showSearchEngine} setOpen={setShowSearchEngine} />
      </div>
    </Box>
  );
};

export default AnalyticDash;
