import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { CheckCircle, Flag, People } from "@mui/icons-material";
import { CircularProgress } from "@material-ui/core";
import {
  Pie,
  PieChart,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
import { API } from "../../api";

const GraphCharts = () => {
  const [usersNotRegistered, setUsersNotRegistered] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [levelsCompleted, setLevelsCompleted] = useState([]);
  const [activitesCompleted, setActivitesCompleted] = useState([]);
  const [challengesCompleted, setChallengesCompleted] = useState([]);
  const [yesterdayUsers, setYesterdayUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Metric Card
  const icons = {
    "Activities Completed": <CheckCircle className="text-blue-500" />,
    "Challenges Completed": <Flag className="text-blue-500" />,
    "Yesterday's Users": <People className="text-blue-500" />,
  };
  const MetricCard = ({ title, value }) => (
    <Box
      className="bg-white p-4 rounded-xl shadow-md transition-all hover:shadow-lg hover:scale-[1.02] cursor-pointer"
      sx={{
        p: 4, // Increased padding
        bgcolor: "background.paper",
        borderRadius: 3, // More rounded corners
        boxShadow: 1,
        height: "100%",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          boxShadow: 3,
          transform: "scale(1.02)",
        },
      }}
    >
      <Typography
        variant="subtitle2"
        color="text.primary"
        className="text-gray-700 font-medium text-lg"
        gutterBottom
      >
        {title}
      </Typography>
      <Box className="flex items-center justify-between">
        <Typography
          variant="h4"
          component="div"
          className="text-gray-900 font-semibold"
        >
          {value}
        </Typography>

        {icons[title] || null}
      </Box>
      <Divider className="my-2" />
    </Box>
  );

  // Speedometer Chart
  const COLORS2 = ["var(--blue-de-france)", "var(--blue-de-france-40)"]; // active and inactive colors
  const GaugeChart = ({ data, title }) => {
    const active = Number.parseInt(data[0]?.registered || data[0]?.active) || 0;
    const inactive =
      Number.parseInt(data[0]?.not_registered || data[0]?.inactive) || 0;

    const total = inactive + active;

    // Avoid division by zero
    const percentage = total > 0 ? (active / total) * 100 : 0;

    // Reversed order: Active first, Inactive second
    const pieData = [
      {
        name: data[0]?.registered !== undefined ? "Registered" : "Active",
        value: active,
      },
      {
        name:
          data[0]?.not_registered !== undefined ? "Not Registered" : "Inactive",
        value: inactive,
      },
    ];

    // Calculate needle angle
    const needleAngle = 180 - percentage * 1.7;

    // Chart center and radius
    const cx = 200,
      cy = 200,
      radius = 90;
    const needleLength = 100;
    const needleWidth = 9; // Width of the triangle base
    const radian = Math.PI / 180;

    // Calculate needle tip and base points
    const xp = cx + needleLength * Math.cos(-needleAngle * radian);
    const yp = cy + needleLength * Math.sin(-needleAngle * radian);
    const xba = cx + (needleWidth / 2) * Math.sin(-needleAngle * radian);
    const yba = cy - (needleWidth / 2) * Math.cos(-needleAngle * radian);
    const xbb = cx - (needleWidth / 2) * Math.sin(-needleAngle * radian);
    const ybb = cy + (needleWidth / 2) * Math.cos(-needleAngle * radian);

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <Typography
          variant="subtitle2"
          color="text.primary"
          className="text-gray-700 font-medium"
          gutterBottom
        >
          <span className="ml-4 text-base">{title}</span>
        </Typography>
        <Divider className="my-2" />
        <ResponsiveContainer width="100%" height={270}>
          <PieChart>
            {/* Semi-Circle Pie */}
            <Pie
              data={pieData}
              cx={cx}
              cy={cy}
              startAngle={180}
              endAngle={0}
              innerRadius={90}
              outerRadius={110}
              paddingAngle={0}
              dataKey="value"
              className="cursor-pointer"
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS2[index]} />
              ))}
            </Pie>

            {/* Needle (Triangle Pointer) */}
            {total > 0 && (
              <>
                <circle
                  cx={cx}
                  cy={cy}
                  r={5}
                  fill="var(--prussian-blue)"
                  stroke="none"
                />
                <path
                  d={`M${xba},${yba} L${xbb},${ybb} L${xp},${yp} Z`}
                  fill="var(--prussian-blue)"
                />
              </>
            )}

            <Tooltip />
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                top: "5%",
                width: data[0]?.registered !== undefined ? "145px" : "100px",
              }}
            />
          </PieChart>
        </ResponsiveContainer>
        <Divider className="my-2" />
      </div>
    );
  };

  // Levels Chart
  const LevelsChart = ({ data }) => {
    // Dummy data
    // const dummyData = [
    //   { levels_completed: "1", no_of_users: "100" },
    //   { levels_completed: "2", no_of_users: "75" },
    //   { levels_completed: "3", no_of_users: "50" },
    //   { levels_completed: "4", no_of_users: "30" },
    //   { levels_completed: "5", no_of_users: "20" },
    // ];

    const chartData = data.map((item) => ({
      level: `Level ${item.levels_completed}`,
      users: Number.parseInt(item.no_of_users, 10),
    }));

    const maxUsers = Math.max(...chartData.map((item) => item.users));
    const yAxisMax = Math.ceil(maxUsers / 10) * 10; // Round up to nearest 10
    const yAxisTicks = Array.from(
      { length: yAxisMax / 10 + 1 },
      (_, i) => i * 10
    );

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <Typography
          variant="subtitle2"
          color="text.primary"
          className="text-gray-700 font-medium"
          gutterBottom
        >
          <span className="ml-4 text-base">Levels Completed</span>
        </Typography>
        <Divider className="my-2" />
        <ResponsiveContainer width="100%" height={270}>
          <BarChart data={chartData}>
            <XAxis dataKey="level" />
            <YAxis domain={[0, yAxisMax]} ticks={yAxisTicks} />
            <Tooltip />
            <Bar dataKey="users" fill="var(--blue-de-france)" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
        <Divider className="my-2" />
      </div>
    );
  };

  const getMetrics = async () => {
    const role = "group_admin";
    try {
      const { data: userNotResgisteredData } = await API.get(
        `charts/user-not-registered/${role}`
      );
      if (userNotResgisteredData) {
        setUsersNotRegistered(userNotResgisteredData);
      }

      const { data: levelsCompletedData } = await API.get(
        `charts/levels-completed-by-users/${role}`
      );
      if (levelsCompletedData) {
        setLevelsCompleted(levelsCompletedData);
      }

      const { data: inactiveUsersData } = await API.get(
        `charts/inactive-users/${role}`
      );
      if (inactiveUsersData) {
        setInactiveUsers(inactiveUsersData);
      }

      const { data: activitesCompletedData } = await API.get(
        `charts/activities-completed/${role}`
      );
      if (activitesCompletedData) {
        setActivitesCompleted(activitesCompletedData);
      }

      const { data: challengesCompletedData } = await API.get(
        `charts/challenges-completed/${role}`
      );
      if (challengesCompletedData) {
        setChallengesCompleted(challengesCompletedData);
      }

      const { data: yesterdayUsersData } = await API.get(
        `charts/yesterday-users/${role}`
      );
      if (yesterdayUsersData) {
        setYesterdayUsers(yesterdayUsersData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  if (loading) {
    return (
      <div className="p-4 my-4 bg-white rounded-lg flex justify-center items-center h-screen">
        <div style={{ marginTop: "-20vh" }}>
          <CircularProgress size={80} />
        </div>
      </div>
    );
  }

  return (
    <Box className="bg-gray-100 p-4">
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={3}>
          {/* Top row with charts */}
          <Grid item xs={12} md={4}>
            <GaugeChart
              data={usersNotRegistered}
              title="User Registration Status"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <GaugeChart data={inactiveUsers} title="User Activity Status" />
          </Grid>
          <Grid item xs={12} md={4}>
            <LevelsChart data={levelsCompleted} />
          </Grid>

          {/* Bottom row with metric cards */}
          <Grid item xs={12} md={4}>
            <MetricCard
              title="Activities Completed"
              value={activitesCompleted[0]?.activities_completed}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MetricCard
              title="Challenges Completed"
              value={challengesCompleted[0]?.challenges_completed}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MetricCard
              title="Yesterday's Users"
              value={yesterdayUsers[0]?.yesterday_users}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GraphCharts;
